import { defineStore } from 'pinia';

export const useAuthStore = defineStore('Auth', {
  state: () => {
    return {
      /**
       * hydrate the state from the session if it exists otherwise null it
       */
      id: null,
      firstName:  null,
      lastName:  null,
      phoneNumber: null,
      buildings: [],
      selectedBuildingId: localStorage.getItem('selectedBuildingId') || '',
      email: sessionStorage.getItem('email') || null,
    };
  },
  getters: {
    /**
     * Whether the user is logged in
     */
    isLoggedIn: (state) => state.email !== null,
  },
  actions: {
    /**
     * Remove the login information and effectively log the user out
     */
    removeAuth() {
      this.token = null;
      this.refreshToken = null;
      this.firstName = null;
      this.lastName = null;
      this.phoneNumber = null;
      this.buildings = [];
      this.selectedBuildingId = '';
      this.email = null;
      this.id = null;
      sessionStorage.clear();
    },
    setUser(user) {
      this.id = user.id;
      this.firstName = user.firstName;
      this.lastName = user.lastName;
      this.email = user.email;
      this.buildings = user.buildings;
      sessionStorage.setItem('email', this.email);
      if (this.isLoggedIn && !localStorage.getItem('selectedBuildingId') && this.buildings.length > 0){
        this.selectedBuildingId = this.buildings[0].id
        localStorage.setItem('selectedBuildingId', this.buildings[0].id);
      }
    },
    setSelectedBuilding(buildingId) {
      this.selectedBuildingId = buildingId
      localStorage.setItem('selectedBuildingId', buildingId);
    }
  },
});
