import { createWebHistory, createRouter } from 'vue-router';
import { useAuthStore } from '@/store/Auth';
import { getCurrentUser } from '@/services/auth';
import routes from './routes.js';

let auth;

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

/**
 * Check if the user is logged in
 *
 * @param {object} to The route to navigate to
 */
const checkUserLoggedIn = (to) => {
  return !to.meta.requiresAuth || auth.isLoggedIn;
};

/**
 * Check the permissions of a user and route
 *
 * @param {object} to The route to navigate to
 */
const checkPermissions = (to) => {
  const authStore = useAuthStore();
  if (to.meta.requiresAuth && !authStore.isLoggedIn) {
    return false;
  }
  return true;
};

router.beforeEach(async (to, from, next) => {
  const goToPage = (routeName) => {
    return next({ name: routeName });
  };

  if (!auth) auth = useAuthStore();

  try {
    const user = await getCurrentUser();
    auth.setUser(user);
  } catch (e) {
    auth.removeAuth();
  }

  if (!checkUserLoggedIn(to)) {
    return goToPage('login');
  }

  if (to.name === 'login' && auth.isLoggedIn) {
    return goToPage('control');
  }

  if (!checkPermissions(to)) {
    return goToPage('control');
  }

  return next();
});

router.onError((error, to) => {
  if (error.message.includes('Failed to fetch dynamically imported module')) {
    window.location = to.fullPath;
  }
});

export default router;
